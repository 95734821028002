import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.0.0_react@18.2.0/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.1_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.1_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-loading-skeleton@3.3.0_react@18.2.0/node_modules/react-loading-skeleton/dist/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-loading-skeleton@3.3.0_react@18.2.0/node_modules/react-loading-skeleton/dist/skeleton.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.1_@babel+core@7.24.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/actions.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/NewsPopup.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/NextImageWithFallback.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/contexts/AuthContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css")